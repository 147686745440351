unit DashboardItemSingleSummary;


interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WebForm.Core,
  DB,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  Vcl.Controls,
  WEBLib.Grids,
  WEBLib.ExtCtrls,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  SharedDataModule,
  Simple.Dashboard.Return.Types,
  BaseDashboardItem,
  Data.DB, VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics,
  VCL.TMSFNCGraphicsTypes, VCL.TMSFNCChart, Vcl.Forms;


type
  TDashboardItemSingleSummary = class(TBaseDashboardItem)
  private
  protected
    procedure CreateComponents; override;
    procedure DisplayData; override;
    procedure NewSummarySelected; override;
    function GetChartHeight: integer; override;
    function GetSummaryRowCount: integer; override;
    function GetSummaryColCount: integer; override;

  public
  end;


implementation

{ TDashboardItemSummaryTop5 }

procedure TDashboardItemSingleSummary.CreateComponents;
begin
  inherited;
  CreateChart;
  CreateComboBox;
end;


procedure TDashboardItemSingleSummary.DisplayData;
begin
  inherited;
  AddChartAndData('');
end;

function TDashboardItemSingleSummary.GetChartHeight: integer;
begin
  result := 540;
end;

function TDashboardItemSingleSummary.GetSummaryColCount: integer;
begin
  //result := Length(Configuration.ColumnHeadings);
  result := SummaryFieldCount;
end;

function TDashboardItemSingleSummary.GetSummaryRowCount: integer;
begin
  result := 1;
end;

procedure TDashboardItemSingleSummary.NewSummarySelected;
var
  lDrillDownSQL: string;
  lDrillDownFieldName: string;
  lNewSummaryParam: string;

begin
  lNewSummaryParam := SummaryComboBox.Items[SummaryComboBox.ItemIndex];
  lDrillDownFieldName := '@' + Configuration.DrillDownFieldName;
  lDrillDownSQL := StringReplace(DrillDownSQL, lDrillDownFieldName, lNewSummaryParam, [rfReplaceAll, rfIgnoreCase]);
  RunSummarySQL(lDrillDownSQL);
  CreateSummaryFieldsTableRowIndex(ChartData, SummaryComboBox.ItemIndex);
end;




end.

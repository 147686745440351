unit UserDashboardConfiguration;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WebForm.Core, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.CheckLst, WEBLib.StdCtrls, Sys.Return.Types, System.Generics.Collections,
  DB, WEBLib.Grids, WEBLib.ExtCtrls, Types, Vcl.Grids;

type
  TUserDashboardForm = class(TCoreWebForm)
    SaveButton: TButton;
    CloseButton: TButton;
    DashItemTable: TTableControl;
    DIList: TListBox;
    DISelected: TListBox;
    UpButton: TButton;
    DownButton: TButton;
    RightButton: TButton;
    LeftButton: TButton;
    procedure CloseButtonClick(Sender: TObject);
    procedure SaveButtonClick(Sender: TObject);
    procedure DashboardItemsClickCheck(Sender: TObject);
    procedure DashItemTableClickCell(Sender: TObject; ACol, ARow: Integer);
    procedure UpButtonClick(Sender: TObject);
    procedure DownButtonClick(Sender: TObject);
    procedure LeftRightClick(Sender: TObject);
    procedure DIListClick(Sender: TObject);
    procedure DISelectedClick(Sender: TObject);
  private
    { Private declarations }
    FDashboardItemTitles: TList<TDashboardItemTitle>;
    procedure SetDashboardItemTitles(ADashboardItemTitles: TList<TDashboardItemTitle>);
    procedure SetUpTable(ARowCount: integer);
    procedure SaveDashboardItemConfiguration;


    procedure MoveUpDown(ADirection: integer);
    procedure MoveLeftRight;
    procedure ReorderSelected;

  public
    { Public declarations }
    DragSection, DragItem: integer;
    property TenantDashboardItems: TList<TDashboardItemTitle> read FDashboardItemTitles write SetDashboardItemTitles;
  protected procedure LoadDFMValues; override; end;

var
  UserDashboardForm: TUserDashboardForm;

implementation

{$R *.dfm}

{ TUserDashboardForm }

procedure TUserDashboardForm.SetUpTable(ARowCount: integer);
var
  lRowCount: integer;
begin
  lRowCount := ARowCount;
  DashItemTable.RowCount := lRowCount + 1;
  DashItemTable.ColWidths[0] := 40;
  DashItemTable.ColWidths[1] := 40;
  DashItemTable.ColWidths[2] := 200;
  DashItemTable.ColWidths[3] := 40;

end;

procedure TUserDashboardForm.CloseButtonClick(Sender: TObject);
begin
//  inherited;
  ModalResult := mrClose;
end;

procedure TUserDashboardForm.DashboardItemsClickCheck(Sender: TObject);
var
  lSelected: integer;
begin
end;

procedure TUserDashboardForm.DashItemTableClickCell(Sender: TObject; ACol,
  ARow: Integer);
  var
   lText: string;
begin
  inherited;

  if ACol = 1 then
  begin
    lText := DashItemTable.Cells[ACol, ARow];

    if lText = '1' then
      DashItemTable.Cells[ACol, ARow] := '0'
    else
      DashItemTable.Cells[ACol, ARow] := '1';

  end;
end;

procedure TUserDashboardForm.DIListClick(Sender: TObject);
begin
  if DIList.ItemIndex > 0 then
  begin
    RightButton.Enabled := true;
    LeftButton.Enabled := false;
    DISelected.ItemIndex := -1;
  end;
end;

procedure TUserDashboardForm.DISelectedClick(Sender: TObject);
begin
  if DISelected.ItemIndex > 0 then
  begin
    LeftButton.Enabled := true;
    RightButton.Enabled := false;
//    DIList.ItemIndex := -1;
  end;
end;

procedure TUserDashboardForm.SaveButtonClick(Sender: TObject);
begin
  ReorderSelected;
  SaveDashboardItemConfiguration;
  ModalResult := mrOk;
end;

procedure TUserDashboardForm.SetDashboardItemTitles(
  ADashboardItemTitles: TList<TDashboardItemTitle>);
var
  lIdx: Integer;
  lDashboardItemTitle: TDashboardItemTitle;
  lCount: integer;
  lEditNameId: string;
begin
  FDashboardItemTitles := ADashboardItemTitles;
  SetUpTable(FDashboardItemTitles.Count);

  lCount := ADashboardItemTitles.Count;

  DashItemTable.Cells[0,0] := 'Id';
  DashItemTable.Cells[1,0] := 'Selected';
  DashItemTable.Cells[2,0] := 'Dashboard Item Name';
  DashItemTable.Cells[3,0] := 'Position';

  for lIdx := 0 to lCount-1 do
  begin
    lDashboardItemTitle := ADashboardItemTitles[lIdx];
    DashItemTable.Cells[0, lIdx+1] := inttostr(lDashboardItemTitle.Id);

    if lDashboardItemTitle.Selected then
    begin
      DashItemTable.Cells[1, lIdx+1] := '1';
      DISelected.Items.Add(lDashboardItemTitle.Title);
      //DISelected.Items.Add(lDashboardItemTitle.Title);
      //DISelected.Items.Insert(lDashboardItemTitle.OrderIndex-1, lDashboardItemTitle.Title);
      //DISelected.Items.AddObject(lDashboardItemTitle.Title, lDashboardItemTitle);
    end
    else
    begin
      DashItemTable.Cells[1, lIdx+1] := '0';
      DIList.Items.Add(lDashboardItemTitle.Title);
      //DISelected.Items.AddObject(lDashboardItemTitle.Title, lDashboardItemTitle);
    end;

    DashItemTable.Cells[2, lIdx+1] := lDashboardItemTitle.Title;
    DashItemTable.Cells[3, lIdx+1] := inttostr(lDashboardItemTitle.OrderIndex);
  end;
end;

procedure TUserDashboardForm.SaveDashboardItemConfiguration;
var
  lIdx: integer;
  lDashboardItemCount: integer;
  lDashCount: integer;

  lId: string;
  lSelected: string;
  lTitle: string;
  lPosition: string;
  lDashboardItemTitles: TDashboardItemTitle;
  lCount: integer;
begin
  FDashboardItemTitles.Clear;
  lDashboardItemCount := DashItemTable.RowCount;
  lDashCount := FDashboardItemTitles.Count;

  for lIdx := lDashCount-1 downto 0 do
    FDashboardItemTitles.Delete(lIdx);

  for lIdx := 0 to lDashboardItemCount-1 do
  begin
    try
      lId := DashItemTable.Cells[0,lIdx+1];
      lSelected := DashItemTable.Cells[1,lIdx+1];
      lTitle := DashItemTable.Cells[2,lIdx+1];
      lPosition := DashItemTable.Cells[3,lIdx+1];

      lDashboardItemTitles := TDashboardItemTitle.Create;
      lDashboardItemTitles.Id := strtoint(lId);
      lDashboardItemTitles.Title := lTitle;


      if lSelected = '1' then
      begin
        lDashboardItemTitles.Selected := True;
        if (lPosition <> '0') then
          lDashboardItemTitles.OrderIndex := strtoint(lPosition);
        //else
        //  lDashboardItemTitles.OrderIndex := lIdx+1;
      end
      else
      begin
        lDashboardItemTitles.Selected := False;
        lDashboardItemTitles.OrderIndex := 0;
      end;

      FDashboardItemTitles.Add(lDashboardItemTitles);
    except

    end;

  end;
end;


procedure TUserDashboardForm.UpButtonClick(Sender: TObject);
begin
  MoveUpDown(-1);
end;

procedure TUserDashboardForm.DownButtonClick(Sender: TObject);
begin
  MoveUpDown(1);
end;

procedure TUserDashboardForm.LeftRightClick(Sender: TObject);
begin
  MoveLeftRight;
end;

procedure TUserDashboardForm.MoveUpDown(ADirection: integer);
var
  lCurrentPosition: integer;
  lNewPosition: integer;

begin
  lCurrentPosition := DISelected.ItemIndex;
  lNewPosition := lCurrentPosition + ADirection;

  if (lNewPosition > -1) and (lNewPosition < DISelected.Count) then
  begin
    DISelected.Items.Move(lCurrentPosition, lNewPosition);
    DISelected.ItemIndex := lNewPosition;

    //if DISelected.ItemIndex = 0 then
    //  UpButton.Enabled := False
    //else if DISelected.ItemIndex = (DISelected.Count-1) then
    //  DownButton.Enabled := False
    //else
    //begin
    //  UpButton.Enabled := True;
    //  DownButton.Enabled := True;
    //end;
  end;
end;

procedure TUserDashboardForm.MoveLeftRight;
var
  lPos: integer;
  lDITitle: string;
begin

  if DISelected.ItemIndex <> -1 then
  begin
    lDITitle := DISelected.Items[DISelected.ItemIndex];
    DISelected.Items.Delete(DISelected.ItemIndex);

    DIList.Items.Add(lDITitle);
    //DISelected.ItemIndex := -1;
    //DIList.ItemIndex := DIList.Count;
  end
  else if DIList.ItemIndex <> -1 then
  begin
    lDITitle := DIList.Items[DIList.ItemIndex];
    DIList.Items.Delete(DIList.ItemIndex);
    DISelected.Items.Add(lDITitle);
    //DIList.ItemIndex := -1;
    //DISelected.ItemIndex := DIList.Count;
  end;
end;

procedure TUserDashboardForm.ReorderSelected;
var
  lIdx: integer;
  lCount: integer;
  lTableIdx: integer;
  lSelectedTitle: string;
  lTitle: string;
begin
  lCount := DISelected.Count;

  for lTableIdx := 0 to DashItemTable.RowCount-2 do
  begin
    DashItemTable.Cells[1,lTableIdx+1] := '0';
    DashItemTable.Cells[3,lTableIdx+1] := '0';
  end;

  for lIdx := 0 to lCount-1 do
  begin
    lSelectedTitle := DISelected.Items[lIdx];

    for lTableIdx := 0 to DashItemTable.RowCount-1 do
    begin
      lTitle := DashItemTable.Cells[2, lTableIdx+1];

      if lSelectedTitle = lTitle then
      begin
        DashItemTable.Cells[1,lTableIdx+1] := '1';
        DashItemTable.Cells[3, lTableIdx+1] := inttostr(lIdx+1);
        break;
      end;
    end;
  end;
end;

procedure TUserDashboardForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  SaveButton := TButton.Create('SaveButton');
  CloseButton := TButton.Create('CloseButton');
  DashItemTable := TTableControl.Create('DITable');
  DIList := TListBox.Create(Self);
  DISelected := TListBox.Create(Self);
  UpButton := TButton.Create('UpButton');
  DownButton := TButton.Create('DownButton');
  RightButton := TButton.Create('RightButton');
  LeftButton := TButton.Create('LeftButton');

  SaveButton.BeforeLoadDFMValues;
  CloseButton.BeforeLoadDFMValues;
  DashItemTable.BeforeLoadDFMValues;
  DIList.BeforeLoadDFMValues;
  DISelected.BeforeLoadDFMValues;
  UpButton.BeforeLoadDFMValues;
  DownButton.BeforeLoadDFMValues;
  RightButton.BeforeLoadDFMValues;
  LeftButton.BeforeLoadDFMValues;
  try
    Width := 696;
    Height := 644;
    SaveButton.SetParentComponent(Self);
    SaveButton.Name := 'SaveButton';
    SaveButton.Left := 248;
    SaveButton.Top := 16;
    SaveButton.Width := 96;
    SaveButton.Height := 25;
    SaveButton.Caption := 'Save';
    SaveButton.ChildOrder := 1;
    SaveButton.HeightPercent := 100.000000000000000000;
    SaveButton.WidthPercent := 100.000000000000000000;
    SetEvent(SaveButton, Self, 'OnClick', 'SaveButtonClick');
    CloseButton.SetParentComponent(Self);
    CloseButton.Name := 'CloseButton';
    CloseButton.Left := 248;
    CloseButton.Top := 47;
    CloseButton.Width := 96;
    CloseButton.Height := 25;
    CloseButton.Caption := 'Close';
    CloseButton.ChildOrder := 1;
    CloseButton.HeightPercent := 100.000000000000000000;
    CloseButton.WidthPercent := 100.000000000000000000;
    SetEvent(CloseButton, Self, 'OnClick', 'CloseButtonClick');
    DashItemTable.SetParentComponent(Self);
    DashItemTable.Name := 'DashItemTable';
    DashItemTable.Left := 16;
    DashItemTable.Top := 352;
    DashItemTable.Width := 561;
    DashItemTable.Height := 300;
    DashItemTable.BorderColor := clSilver;
    DashItemTable.ChildOrder := 5;
    DashItemTable.Options.CellBorders := True;
    SetEvent(DashItemTable, Self, 'OnClickCell', 'DashItemTableClickCell');
    DashItemTable.ColCount := 4;
    DIList.SetParentComponent(Self);
    DIList.Name := 'DIList';
    DIList.Left := 16;
    DIList.Top := 16;
    DIList.Width := 200;
    DIList.Height := 305;
    DIList.HeightPercent := 100.000000000000000000;
    DIList.ItemHeight := 15;
    DIList.MultiSelect := True;
    DIList.WidthPercent := 100.000000000000000000;
    SetEvent(DIList, Self, 'OnClick', 'DIListClick');
    DIList.ItemIndex := -1;
    DISelected.SetParentComponent(Self);
    DISelected.Name := 'DISelected';
    DISelected.Left := 377;
    DISelected.Top := 8;
    DISelected.Width := 200;
    DISelected.Height := 313;
    DISelected.HeightPercent := 100.000000000000000000;
    DISelected.ItemHeight := 15;
    DISelected.MultiSelect := True;
    DISelected.WidthPercent := 100.000000000000000000;
    SetEvent(DISelected, Self, 'OnClick', 'DISelectedClick');
    DISelected.ItemIndex := -1;
    UpButton.SetParentComponent(Self);
    UpButton.Name := 'UpButton';
    UpButton.Left := 600;
    UpButton.Top := 112;
    UpButton.Width := 24;
    UpButton.Height := 36;
    UpButton.Caption := 'U';
    UpButton.ChildOrder := 5;
    UpButton.HeightPercent := 100.000000000000000000;
    UpButton.WidthPercent := 100.000000000000000000;
    SetEvent(UpButton, Self, 'OnClick', 'UpButtonClick');
    DownButton.SetParentComponent(Self);
    DownButton.Name := 'DownButton';
    DownButton.Left := 600;
    DownButton.Top := 154;
    DownButton.Width := 24;
    DownButton.Height := 36;
    DownButton.Caption := 'D';
    DownButton.ChildOrder := 5;
    DownButton.HeightPercent := 100.000000000000000000;
    DownButton.WidthPercent := 100.000000000000000000;
    SetEvent(DownButton, Self, 'OnClick', 'DownButtonClick');
    RightButton.SetParentComponent(Self);
    RightButton.Name := 'RightButton';
    RightButton.Left := 280;
    RightButton.Top := 94;
    RightButton.Width := 36;
    RightButton.Height := 24;
    RightButton.Caption := '->';
    RightButton.ChildOrder := 5;
    RightButton.HeightPercent := 100.000000000000000000;
    RightButton.WidthPercent := 100.000000000000000000;
    SetEvent(RightButton, Self, 'OnClick', 'LeftRightClick');
    LeftButton.SetParentComponent(Self);
    LeftButton.Name := 'LeftButton';
    LeftButton.Left := 280;
    LeftButton.Top := 124;
    LeftButton.Width := 36;
    LeftButton.Height := 24;
    LeftButton.Caption := '<-';
    LeftButton.ChildOrder := 5;
    LeftButton.HeightPercent := 100.000000000000000000;
    LeftButton.WidthPercent := 100.000000000000000000;
    SetEvent(LeftButton, Self, 'OnClick', 'LeftRightClick');
  finally
    SaveButton.AfterLoadDFMValues;
    CloseButton.AfterLoadDFMValues;
    DashItemTable.AfterLoadDFMValues;
    DIList.AfterLoadDFMValues;
    DISelected.AfterLoadDFMValues;
    UpButton.AfterLoadDFMValues;
    DownButton.AfterLoadDFMValues;
    RightButton.AfterLoadDFMValues;
    LeftButton.AfterLoadDFMValues;
  end;
end;

end.

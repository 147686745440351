unit DashboardForm2;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WEBLib.DB,
  WEBLib.ExtCtrls,
  WEBLib.WebCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  Vcl.Controls,
  Vcl.Grids,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Data.DB, VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics,
  VCL.TMSFNCGraphicsTypes, VCL.TMSFNCChart, //DashboardItem,
  App.Helper, DB,

  //rest.Json,

  BaseDashboardItem, DashboardItemSummaryTop5, DashboardItemTableOnly,
  DashboardItemSingleSummary,DashboardItemSummaryIncluded, DashboardItemLikeForLike,
  Vcl.Imaging.GIFImg, WEBLib.TreeNodes, Vcl.ComCtrls, WEBLib.ComCtrls,
  WEBLib.CheckLst, System.Generics.Collections, SMX.XData.Utils, Sys.Return.Types,
  VCL.TMSFNCCustomControl, VCL.TMSFNCTreeViewBase, VCL.TMSFNCTreeViewData,
  VCL.TMSFNCCustomTreeView, VCL.TMSFNCTreeView;//, Simple.Dashboard.Return.Types;

type
  TDashboard2 = class(TForm)
    WaitMessage: TWaitMessage;
    ChartTimer: TTimer;
    BranchTreeView: TTreeView;
    StartDatePicker: TDateTimePicker;
    EndDatePicker: TDateTimePicker;
    RefreshButton: TButton;
    ConfigurationButton: TButton;
    WebButton1: TButton;
    UserDashboardButton: TButton;
    RefreshDashboardItemsTimer: TTimer;

    procedure WebFormShow(Sender: TObject);
    procedure ChartTimerTimer(Sender: TObject);
    procedure DatePickerChange(Sender: TObject);
    procedure RefreshButtonClick(Sender: TObject);
    procedure BranchTreeViewChange(Sender: TObject; Node: TTreeNode);
    procedure ConfigurationButtonClick(Sender: TObject);
    procedure BranchTreeViewClick(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure UserDashboardButtonClick(Sender: TObject);
    procedure RefreshDashboardItemsTimerTimer(Sender: TObject);
  private
    { Private declarations }
    FUserId: integer;

    FDashboardParams: JS.TJSObject;
    //FDashboardParamsStr: string;
    FDashboardList: TStringList;

    FDashboardItemTemplates: TList<TDashboardItemTemplate>;
    FDashboardItemTitles: TList<TDashboardItemTitle>;

    FBranchStructureJSON: string;
    FGroupList: TStringList;
    FBranchList: TStringList;
    FBranchListForQuery: TStringList;
    FUserConfig: TUserConfig;
    FUserBranchList: TStringList;
    FStartDayOfWeek: integer;

    procedure CreateDashboardItems;
    procedure CreateDashboardItem(ADashBoardItemTemplate: TDashboardItemTemplate);
    procedure InitialiseDashboard;

    procedure RefreshDashboard(ADashboardId: integer);
    procedure UpdateDashboardItemsParameters;

    procedure GetDashboardItemList;
    procedure GetBranchIdsList;
    procedure UpdateBranchIdsList;
    procedure ImplementParameters;

    [async]
    procedure GetUserConfiguration; async;
    [async]
    procedure SetUserConfiguration; async;

    [async]
    procedure GetBranches; async;
    procedure GetSelectedBranches;
    procedure BranchUpdate;
    function SortBranchIdsParameter:string;
    [async]
    procedure GetUserDashboardItems; async;

    [async]
    procedure GetBranchStructure; async;
    [async]
    procedure ConfigurationPopUp; async;
//    procedure RefreshBranchTreeView;
    function GetDayLikeForLike(ADate: TDate): string;
    [async]
    procedure UserDashboardPopUp; async;
    [async]
    procedure SaveUserDashboardItems(AUserDashboardItemTitles: TList<TDashboardItemTitle>); async;

    procedure RefreshDashboardItems;

    function GetParameters(AParameters: TArray<String>): string;

//    function SetParameters(lDashboardItem: TBaseDashboardItem):string;

  public
    { Public declarations }
    [async]
//    function GetTenantDashboardItems: integer;
//    function GetTenantDashboardItems: TList<TDashboardItemTitle>;
    procedure GetTenantDashboardItems; async;//: TList<TDashboardItemTitle>;

  protected procedure LoadDFMValues; override; end;

var
  Dashboard2: TDashboard2;

implementation

{$R *.dfm}

uses
  XData.Web.Client,
  MainForm,
  MainDataModule,
  SMX.Web.Service.Consts,
  SharedDataModule,
  Job.DetailForm.Manager,
  JobDetailBaseForm,
  SMX.Web.Utils,
  SMX.Web.Document.Utils,
  SMX.Web.Table.Simple,
  DateUtils,
  Auth.Service,
  Configuration,
  UserDashboardConfiguration;


procedure TDashboard2.InitialiseDashboard;
begin
  FUserId := MainData.UserId;

  FDashboardList := TStringList.Create;
  FDashboardItemTemplates := TList<TDashboardItemTemplate>.Create;

  FBranchListForQuery := TStringList.Create;
  //This will contain all valid branches used by the User
  FUserBranchList := TStringList.Create;

  //SetUserConfiguration; //KSS
  GetUserConfiguration;
  GetBranches;
  GetTenantDashboardItems;
  GetUserDashboardItems;
end;

procedure TDashboard2.CreateDashboardItems;
var
  lIdx: integer;
  lDashboardItemTemplate: TDashboardItemTemplate;
begin
  for lIdx := 0 to FDashboardItemTemplates.Count-1 do
  begin
    lDashboardItemTemplate := FDashboardItemTemplates[lIdx];
    CreateDashboardItem(lDashboardItemTemplate);
  end;
end;

procedure TDashboard2.CreateDashboardItem(ADashBoardItemTemplate: TDashboardItemTemplate);
var
  lDashboardItem: TBaseDashboardItem;
  lIndex: Integer;
  lDashboardParameters: string;
  lParameters: string;
begin
  if ADashboardItemTemplate.DashboardItemType = 'SQLSummaryTop5' then
    lDashboardItem := TDashboardItemSummaryTop5.Create(Self)
  else if ADashboardItemTemplate.DashboardItemType = 'SQLTable' then
    lDashboardItem := TDashboardItemTableOnly.Create(Self)
  else if ADashboardItemTemplate.DashboardItemType = 'SQLSummaryIncluded' then
    lDashboardItem := TDashboardItemSummaryIncluded.Create(Self)
  else if ADashboardItemTemplate.DashboardItemType = 'SQLSingleSummary' then
    lDashboardItem := TDashboardItemSingleSummary.Create(Self)
  else if ADashboardItemTemplate.DashboardItemType = 'SQLLikeForLike' then
    lDashboardItem := TDashboardItemLikeForLike.Create(Self);

  lDashboardItem.DashboardId := ADashboardItemTemplate.Id;
  lDashboardItem.Parent := self;

//  lDashboardItem.DashboardParameters := FDashboardParamsStr;
  lDashboardItem.Template := ADashBoardItemTemplate;
  lParameters := GetParameters(lDashboardItem.Configuration.Parameters);
  lDashboardItem.DashboardParameters := lParameters;

  lDashboardItem.Run;

  FDashboardList.AddObject(lDashboardItem.Title, lDashboardItem);
end;

procedure TDashboard2.RefreshButtonClick(Sender: TObject);
begin
  GetSelectedBranches;

  UpdateBranchIdsList;
  ImplementParameters;
  RefreshDashboard(0);
end;

procedure TDashboard2.RefreshDashboard(ADashboardId: integer);
var
  lDashboardItem: TBaseDashboardItem;
  lIndex: Integer;
begin
//  for lIndex := 0 to FDashboardItemsTemplates.Count-1 do
  for lIndex := 0 to FDashboardList.Count-1 do
  begin
    lDashboardItem := TBaseDashboardItem(FDashboardList.Objects[lIndex]);

//KSS see to this    lDashboardItem.DashboardParameters := FDashboardParamsStr;
    lDashboardItem.Refresh;
  end;
end;

procedure TDashboard2.RefreshDashboardItemsTimerTimer(Sender: TObject);
begin
  RefreshDashboardItemsTimer.Enabled := False;
  RefreshDashboardItems;
end;

procedure TDashboard2.RefreshDashboardItems;
var
  lIdx: integer;
  lCount: integer;
  lDashboardItem: TBaseDashboardItem;
begin
  lCount := FDashboardList.Count;

  for lIdx := lCount-1 downto 0 do
  begin
    lDashboardItem := TBaseDashboardItem(FDashboardList.Objects[lIdx]);
    lDashboardItem.Free;
  end;

  GetUserDashboardItems;
end;

procedure TDashboard2.WebFormShow(Sender: TObject);
begin
  InitialiseDashboard;
end;

procedure TDashboard2.BranchTreeViewChange(Sender: TObject; Node: TTreeNode);
begin
  UpdateBranchIdsList;
end;

procedure TDashboard2.ChartTimerTimer(Sender: TObject);
var
  lIdx: integer;
//  MyChart: TDashboardItem;
begin
  ChartTimer.Enabled := False;

//  for lIdx := 0 to ChartList.Count-1 do
//  begin
//    MyChart := TDashboardChart(ChartList.Objects[lIdx]);
//    MyChart.Parent := Self;
//  end;

//  WaitMessage.Hide;
end;

procedure TDashboard2.ConfigurationButtonClick(Sender: TObject);
begin
  ConfigurationPopUp;
end;

procedure TDashboard2.ConfigurationPopUp;
var
  ConfigForm: TConfigurationForm;
begin
  ConfigForm := TConfigurationForm.Create(Self);
  try
    ConfigForm.Popup := True;
    ConfigForm.PopupOpacity := 1;
    await(TConfigurationForm, ConfigForm.Load());
    ConfigForm.BranchStructure := FBranchStructureJSON;
    await(TModalResult, ConfigForm.Execute);
  finally
    ConfigForm.Free;
    ConfigForm := nil;
  end;
end;

procedure TDashboard2.GetBranchIdsList;
begin

end;

procedure TDashboard2.GetBranchStructure;
begin
  FBranchStructureJSON := Await(SharedData.Dashboard.GetBranchStructure());
  //lRegionArray := JS.toArray(TJSJSON.parse(lBranchStructure));
end;

procedure TDashboard2.GetDashboardItemList;
begin

end;

procedure TDashboard2.GetSelectedBranches;
var
  lCount: integer;
  lIdx: integer;
  lNode : TTreeNode;
begin
// lCount := BranchTreeView.SelectionCount;

//  lNode := BranchTreeView.Selections[0];

//  BranchTreeView.Subselect()

//  for lIdx := 0 to lCount-1 do
//  begin

//    ListBox.Items.Add(lNode.Text);
//  end;
end;

procedure TDashboard2.ImplementParameters;
var
//  lDashboardParams: JS.TJSObject;
  lDashboardParamsStr: string;

  lDate: TDate;
  lDateStr: string;
  lBranchIdList: string;
  //lTodayDate: TDate;
  lDateSpan: integer;
  lCurrentYear: string;
  lEndYear: integer;
  lStartYear: integer;
  lYearStart: string;
  lYearEnd: string;
  lMonthStart: string;
  lMonthEnd: string;
  lYear: integer;
begin

  lBranchIdList := SortBranchIdsParameter;
  lDateSpan := 7;
  lDate := Date - 180;
//  lDate := Date - 20;
//  lEndYear := FormatDateTime('YYYY', Now);
//  lStartYear := FormatDateTime('YYYY', Now);

//  lEndYear := DateUtils.YearOf(Now);
//  lStartYear := lEndYear;

  lCurrentYear := inttostr(DateUtils.YearOf(Now));
  lYearStart := '01.01.' + inttostr(DateUtils.YearOf(Now)-1);
  lYearEnd := '31.12.' + inttostr(DateUtils.YearOf(Now));

  lMonthStart := '01.01.' + inttostr(DateUtils.YearOf(Now));
  lMonthEnd := '31.12.' + inttostr(DateUtils.YearOf(Now));


  FDashboardParams := JS.TJSObject.new;

  //lDate := StartDatePicker.Date;
  StartDatePicker.Date := lDate-lDateSpan;
  lDateStr := FormatDateTime('dd.mm.yyyy', lDate-lDateSpan);
  FDashboardParams.Properties['StartDate'] := lDateStr;
  EndDatePicker.Date := lDate;
  lDateStr := FormatDateTime('dd.mm.yyyy', lDate);
  FDashboardParams.Properties['EndDate'] := lDateStr;
  FDashboardParams.Properties['BranchId'] := lBranchIdList;
  FDashboardParams.Properties['Today'] := FormatDateTime('dd.mm.yyyy', lDate);
  FDashboardParams.Properties['CurrentYear'] := lCurrentYear;//FormatDateTime('YYYY', Now);

  FDashboardParams.Properties['DayLikeForLike'] := GetDayLikeForLike(lDate);

  FDashboardParams.Properties['YearStart'] := lYearStart;//FormatDateTime('YYYY', Now);
  FDashboardParams.Properties['YearEnd'] := lYearEnd;//FormatDateTime('YYYY', Now);

  FDashboardParams.Properties['MonthStart'] := lMonthStart;//FormatDateTime('YYYY', Now);
  FDashboardParams.Properties['MonthEnd'] := lMonthEnd;//FormatDateTime('YYYY', Now);


  FDashboardParams.Properties['LFL_Start_0'] := '04.02.2024';//FormatDateTime('YYYY', Now);
  FDashboardParams.Properties['LFL_End_0'] := '11.02.2024';//FormatDateTime('YYYY', Now);
  FDashboardParams.Properties['LFL_Start_1'] := '05.02.2023';//FormatDateTime('YYYY', Now);
  FDashboardParams.Properties['LFL_End_1'] := '12.02.2023';//FormatDateTime('YYYY', Now);

  FDashboardParams.Properties['LFL_ThisDay_Start'] := '04.02.2024';//FormatDateTime('YYYY', Now);
  FDashboardParams.Properties['LFL_ThisDay_End'] := '05.02.2024';//FormatDateTime('YYYY', Now);
  FDashboardParams.Properties['LFL_PrevDay_Start'] := '05.02.2023';//FormatDateTime('YYYY', Now);
  FDashboardParams.Properties['LFL_PrevDay_End'] := '06.02.2023';//FormatDateTime('YYYY', Now);

  FDashboardParams.Properties['LFL_Day_Start_0'] := '01.01.2024';
  FDashboardParams.Properties['LFL_Day_End_0'] := '01.01.2025';
  FDashboardParams.Properties['LFL_Day_Start_1'] := '01.01.2023';
  FDashboardParams.Properties['LFL_Day_End_1'] := '01.01.2024';

  StartDatePicker.Visible := true;
  EndDatePicker.Visible := true;

  //FDashboardParams := lDashboardParams;
  lDashboardParamsStr := JS.TJSJSON.stringify(FDashboardParams);

//  FDashboardParamsStr := lDashboardParamsStr;
//  UpdateDashboardItemsParameters;
end;

procedure TDashboard2.DatePickerChange(Sender: TObject);
begin
//  ImplementParameters;
end;

procedure TDashboard2.GetUserConfiguration;
var
  lUserConfig: string;

  lConfigObj: JS.TJSObject;
  lConfigJSON: string;

begin
  lUserConfig := Await(SharedData.Dashboard.GetUserConfig(FUserId));

  lConfigJSON := lUserConfig;
  lConfigObj := JS.toObject(TJSJSON.parse(lConfigJSON));
  FUserConfig := TUserConfig(lConfigObj);

end;

procedure TDashboard2.SetUserConfiguration;
var
  lUserConfig: TUserConfig;

  lConfigObj: JS.TJSObject;
  lConfigJSON: string;
  //lOk: boolean;
  lJSONString: string;
  FDashboardParams: JS.TJSObject;
begin
  lUserConfig := TUserConfig.Create;


  FDashboardParams := JS.TJSObject.new;

//  FDashboardParams.Properties['BranchId'] := lBranchIdList;
//  FDashboardParams.Properties['Today'] := FormatDateTime('dd.mm.yyyy', lDate);
//  FDashboardParams.Properties['CurrentYear'] := lCurrentYear;//FormatDateTime('YYYY', Now);


  //FDashboardParams := lDashboardParams;
//  lDashboardParamsStr := JS.TJSJSON.stringify(FDashboardParams);





 // lUserConfig.
  asm debugger; end;  lUserConfig.WeekStartDay := 2;
  lUserConfig.BranchList := ['100', '200', '300'];

//  lJSONString := TJSON.ObjectToJsonString(lUserConfig);
//  lJSONString := ObjectToJsonString(lUserConfig);

  asm debugger; end;  lJSONString := JS.TJSJSON.stringify(lUserConfig);
//  lJSONString := JS.toString(lUserConfig);

  //JS.ob


  asm debugger; end;  if lJSONString = '' then
    Sleep(90);


  Await(SharedData.Dashboard.SetUserConfig(FUserId, lUserConfig));
end;



procedure TDashboard2.GetBranches;
var
  lBranchStructure: string;
  BranchNode, SN, AreaNode: TTreeNode;
  lStream: TStringStream;

  lBranchStructureObj: JS.TJSObject;
  lIdx: integer;
  lIdx2: integer;
  lCount: integer;

  lBranchStructureArray: JS.TJSArray;
  lBranchObj: JS.TJSObject;

  lRegionArray: JS.TJSArray;
  lRegionName: string;
  lRegionObj: JS.TJSObject;
  lBranchesObj: JS.TJSArray;
  //lBranchesObj: JS.TJSValue;
  lBranchArray: JS.TJSArray;

  lBranchName: string;
  lBranchId: string;
  lSubBranch: TStringList;
begin
  FGroupList := TStringList.Create;
  FBranchList := TStringList.Create;

  lBranchStructure := Await(SharedData.Dashboard.GetBranchStructure());
  FBranchStructureJSON := lBranchStructure;
  lRegionArray := JS.toArray(TJSJSON.parse(lBranchStructure));

  BranchTreeView.BeginUpdate;

  for lIdx := 0 to lRegionArray.Length-1 do
  begin
    lSubBranch := TStringList.Create;
    lRegionObj := JS.toObject(lRegionArray[lIdx]);
    lBranchArray := JS.toArray(lRegionObj);

    lRegionName := String(lRegionObj.toString);
    lRegionName := Copy(lRegionName, 1, Pos(',', lRegionName)-1);
    BranchNode := BranchTreeView.Items.Add(lRegionName);

    FGroupList.AddObject(lRegionName, BranchNode);
    FBranchList.AddObject(lRegionName, lSubBranch);

    BranchNode.NodeType := ntCheckBox;
    //BranchNode.Selected := True;
    //BranchNode.Checked := True;


    for lIdx2 := 1 to lBranchArray.Length-1 do
    begin
      lBranchObj := JS.toObject(lBranchArray.Elements[lIdx2]);

      lBranchName := String(lBranchObj['BranchName']);
      lBranchId   := String(lBranchObj['BranchId']);

      SN := BranchTreeView.Items.AddChild(BranchNode, lBranchName);
      SN.NodeType := ntCheckBox;
      //SN.Selected := True;
      SN.Checked := False;// True;
      lSubBranch.AddObject(lBranchId, SN);
    end;
  end;

  BranchTreeView.EndUpdate;
end;

procedure TDashboard2.UpdateBranchIdsList;
var
  lGroupIdx: integer;
  lBranchIdx: integer;
  lGroupNode: TTreeNode;
  lBranchNode: TTreeNode;
  lChecked: boolean;
  lBranchList: TStringList;
begin
//  BranchTreeView.BeginUpdate;
  FBranchListForQuery.Clear;
  for lGroupIdx := 0 to FGroupList.Count-1 do
  begin
    lBranchList := TStringList(FBranchList.Objects[lGroupIdx]);
    lGroupNode := TTreeNode(FGroupList.Objects[lGroupIdx]);
    //lChecked := lGroupNode.Checked;

    for lBranchIdx := 0 to lBranchList.Count-1 do
    begin
      lBranchNode := TTreeNode(lBranchList.Objects[lBranchIdx]);
      //lBranchNode.Checked := lChecked;

      if lBranchNode.Checked then
      begin
        //ListBox.Items.Add(lBranchList[lBranchIdx]);
        FBranchListForQuery.Add(lBranchList[lBranchIdx]);
      end;
    end;
  end;
//  BranchTreeView.EndUpdate;
end;

procedure TDashboard2.UpdateDashboardItemsParameters;
var
  lDashboardItem: TBaseDashboardItem;
  lIndex: Integer;
begin
  for lIndex := 0 to FDashboardList.Count-1 do
  begin
    lDashboardItem := TBaseDashboardItem(FDashboardList.Objects[lIndex]);
//KSS Check this    lDashboardItem.DashboardParameters := FDashboardParamsStr;
    //lDashboardItem.Refresh;
    (*  MyChart := TDashboardChart.Create(Self);
      MyChart.DashboardIndex := AIndex;
      MyChart.DashboardId := AId;
      MyChart.Parent := self;
      MyChart.DashboardParameters := FDashboardParamsStr;
      MyChart.Run;*)
    //  ChartList.AddObject(AId.ToString, MyChart)
  end;
end;

procedure TDashboard2.BranchTreeViewClick(Sender: TObject);
begin
//  RefreshBranchTreeView;
end;

procedure TDashboard2.BranchUpdate;
begin

end;


function TDashboard2.SortBranchIdsParameter:string;
var
  lIdx: integer;
  lBranchId: string;
  lBranchList: string;
  lLength: integer;
begin
  lBranchList := '';
  for lIdx := 0 to FBranchListForQuery.Count-1 do
  begin
    lBranchId := FBranchListForQuery[lIdx];
    lBranchList := lBranchList + lBranchId + ',';
  end;

  lLength := Length(lBranchList);

  if lLength > 3 then
    lBranchList := Copy(lBranchList, 1, Length(lBranchList)-1)
  else
    lBranchList := '0';

  result := lBranchList;
end;

procedure TDashboard2.GetUserDashboardItems;
var
  lRetval: TXDataClientResponse;
  lList: TJSArray;
  lIdx: integer;
  lUserId: integer;

begin
//  if FDashboardItemTemplates.Count > 0 then
//  begin
//    for lIdx := FDashboardItemTemplates.Count-1 downto 0 do
//    begin
//      FDashboardItemTemplates.Delete(lIdx)
//    end;
//  end;


  lUserId := MainData.UserId;

  if lUserId <> 0 then
  begin
    FDashboardItemTemplates := Await(SharedData.Dashboard.GetDashboardItemTemplates(lUserId));
  end;

  ImplementParameters;
  CreateDashboardItems;
end;

(*
procedure TDashboard2.RefreshBranchTreeView;
var
  lRegionCount: integer;
  lRegionIdx: integer;
  lBranchCount: integer;
  lBranchIdx: integer;
  BranchNode, RegionNode: TTreeNode;
  lName: string;
  lChecked: boolean;
begin
//  ListBox.Items.Clear;
  lRegionCount := BranchTreeView.Items.Count;


  for lRegionIdx := 0 to lRegionCount-1 do
  begin
    RegionNode := BranchTreeView.Items[lRegionIdx];

    lName := RegionNode.Text;
    lChecked := RegionNode.Checked;

    if lChecked then
      lName := lName + ' (C)';

//    ListBox.Items.Add(lName);

    if RegionNode.HasChildren then
    begin
//      BranchNode := RegionNode.GetNextChild(RegionNode);
      lBranchCount := RegionNode.getFirstChild.Count;

      lName := lName + ' B:' + inttostr(lBranchCount);
//      ListBox.Items.Add(lName);

      //for lBranchIdx := 0 to lBranchCount-1 do
      //begin
      //  BranchNode := RegionNode.Item[0];
      //end;


    end
    else
    begin
//      ListBox.Items.Add('NO CHILDREN');
    end;
  end;
end;*)

procedure TDashboard2.WebButton1Click(Sender: TObject);
begin
//  RefreshBranchTreeView;
end;

function TDashboard2.GetDayLikeForLike(ADate: TDate): string;
var
  lSQLParam: string;
  lThisYearStartDate: string;
  lThisYearEndDate: string;
  lLastYearStartDate: string;
  lLastYearEndDate: string;


const
  DAY_LIKE_FOR_LIKE = '(((vt.DateTime >= ''%s'') and (vt.DateTime < ''%s'')) or ' +
               ' ((vt.DateTime >= ''%s'') and (vt.DateTime < ''%s''))) ';

begin
  lThisYearStartDate := FormatDateTime('dd.mm.yyyy', ADate);
  lThisYearEndDate := FormatDateTime('dd.mm.yyyy', ADate+1);
  lLastYearStartDate := FormatDateTime('dd.mm.yyyy', ADate-364);
  lLastYearEndDate := FormatDateTime('dd.mm.yyyy', ADate+1-364);

  lSQLParam := Format(DAY_LIKE_FOR_LIKE, [lThisYearStartDate, lThisYearEndDate, lLastYearStartDate, lLastYearEndDate]);
  result := lSQLParam;
end;


//function TDashboard2.GetTenantDashboardItems: TList<TDashboardItemTitle>;
procedure TDashboard2.GetTenantDashboardItems;//: TList<TDashboardItemTitle>;
var
  lRetval: TXDataClientResponse;
//  lResponse: TJSObject;
  lList: TJSArray;
  lIdx: integer;
//  lCount: integer;
//  lDashboardItemTitles: TList<TDashboardItemTitle>;
  lTenantId: string;
begin
  lTenantId := MainData.TenantId;
  FDashboardItemTitles := Await(SharedData.Dashboard.GetTenantDashboardItems(lTenantId));
end;


procedure TDashboard2.UserDashboardButtonClick(Sender: TObject);
var
  lDashboardItemTitles: TList<TDashboardItemTitle>;
begin
//  lDashboardItemTitles := GetTenantDashboardItemsKSS;

  UserDashboardPopUp;
end;

procedure TDashboard2.SaveUserDashboardItems(AUserDashboardItemTitles: TList<TDashboardItemTitle>);
var
  lUserDashboardItemTitles: TList<TDashboardItemTitle>;
begin
////  FDashboardItemTitles ( AUserDashboardItemTitles):
//  lUserDashboardItemTitles := AUserDashboardItemTitles;//>;

//  if lUserDashboardItemTitles.Count = 0 then
//    sleep(50);

//  lUserDashboardItemTitles := FDashboardItemTitles;//>;
//  if lUserDashboardItemTitles.Count = 0 then
//    sleep(50);


//  Await(SharedData.Dashboard.SaveUserDashboardItemTitles(FUserId, AUserDashboardItemTitles));

  Await(SharedData.Dashboard.SaveUserDashboardItemTitles(FUserId, FDashboardItemTitles));

//  Await(SharedData.Dashboard.Test(FDashboardItemTemplates));
//  Await(SharedData.Dashboard.SaveUserDashboardItemTitles(AUserDashboardItemTitles));
end;

procedure TDashboard2.UserDashboardPopUp;
var
  UserDashboardFrm: TUserDashboardForm;
  lDashboardItemTitles: TList<TDashboardItemTitle>;
begin
//  GetTenantDashboardItems;

  UserDashboardFrm := TUserDashboardForm.Create(Self);
  try
    UserDashboardFrm.Popup := True;
    UserDashboardFrm.PopupOpacity := 1;
    await(TUserDashboardForm, UserDashboardFrm.Load());
    //ConfigForm.BranchStructure := FBranchStructureJSON;
    UserDashboardFrm.TenantDashboardItems := FDashboardItemTitles;
    await(TModalResult, UserDashboardFrm.Execute);
  finally
    if UserDashboardFrm.ModalResult = mrOk then
    begin
      FDashboardItemTitles := UserDashboardFrm.TenantDashboardItems;
      SaveUserDashboardItems(UserDashboardFrm.TenantDashboardItems);
      RefreshDashboardItemsTimer.Enabled := true;
    end;

    UserDashboardFrm.Free;
    UserDashboardFrm := nil;
  end;

//  GetUserDashboardItems;
end;

function TDashboard2.GetParameters(AParameters: TArray<String>): string;
var
  lIdx: Integer;
  lParamName: string;
  lParamValue: string;
  lDashboardParams: JS.TJSObject;
begin
  lDashboardParams := JS.TJSObject.new;

  for lIdx := 0 to Length(AParameters)-1 do
  begin
    lParamName := AParameters[lIdx];

    //JS.toArray(TJSJSON.parse(
    lParamValue := JS.toString(FDashboardParams.Properties[lParamName]);
    lDashboardParams.Properties[lParamName] := lParamValue;
  end;

  result := JS.TJSJSON.stringify(lDashboardParams);
end;

procedure TDashboard2.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WaitMessage := TWaitMessage.Create(Self);
  BranchTreeView := TTreeView.Create('BranchTreeView');
  StartDatePicker := TDateTimePicker.Create('StartDate');
  EndDatePicker := TDateTimePicker.Create('EndDate');
  RefreshButton := TButton.Create('Refresh');
  ConfigurationButton := TButton.Create('Configuration');
  WebButton1 := TButton.Create(Self);
  UserDashboardButton := TButton.Create('UserDashboardButton');
  ChartTimer := TTimer.Create(Self);
  RefreshDashboardItemsTimer := TTimer.Create(Self);

  WaitMessage.BeforeLoadDFMValues;
  BranchTreeView.BeforeLoadDFMValues;
  StartDatePicker.BeforeLoadDFMValues;
  EndDatePicker.BeforeLoadDFMValues;
  RefreshButton.BeforeLoadDFMValues;
  ConfigurationButton.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  UserDashboardButton.BeforeLoadDFMValues;
  ChartTimer.BeforeLoadDFMValues;
  RefreshDashboardItemsTimer.BeforeLoadDFMValues;
  try
    Name := 'Dashboard2';
    Width := 941;
    Height := 677;
    Color := clBtnFace;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WaitMessage.SetParentComponent(Self);
    WaitMessage.Name := 'WaitMessage';
    WaitMessage.Left := 228;
    WaitMessage.Top := 576;
    WaitMessage.Width := 24;
    WaitMessage.Height := 24;
    WaitMessage.Margins.Left := 5;
    WaitMessage.Margins.Top := 5;
    WaitMessage.Margins.Right := 5;
    WaitMessage.Margins.Bottom := 5;
    WaitMessage.HeightStyle := ssAuto;
    WaitMessage.WidthStyle := ssAuto;
    WaitMessage.Opacity := 0.200000000000000000;
    WaitMessage.Picture.LoadFromFile('DashboardForm2.WaitMessage.Picture.gif');
    BranchTreeView.SetParentComponent(Self);
    BranchTreeView.Name := 'BranchTreeView';
    BranchTreeView.Left := 8;
    BranchTreeView.Top := 24;
    BranchTreeView.Width := 244;
    BranchTreeView.Height := 385;
    BranchTreeView.ChildOrder := 1;
    BranchTreeView.ElementPosition := epIgnore;
    BranchTreeView.HeightStyle := ssAuto;
    BranchTreeView.WidthStyle := ssAuto;
    BranchTreeView.HeightPercent := 100.000000000000000000;
    BranchTreeView.WidthPercent := 100.000000000000000000;
    BranchTreeView.TabOrder := 1;
    BranchTreeView.TabStop := True;
    SetEvent(BranchTreeView, Self, 'OnClick', 'BranchTreeViewClick');
    StartDatePicker.SetParentComponent(Self);
    StartDatePicker.Name := 'StartDatePicker';
    StartDatePicker.Left := 16;
    StartDatePicker.Top := 480;
    StartDatePicker.Width := 170;
    StartDatePicker.Height := 22;
    StartDatePicker.HeightStyle := ssAuto;
    StartDatePicker.WidthStyle := ssAuto;
    StartDatePicker.BorderStyle := bsSingle;
    StartDatePicker.ChildOrder := 3;
    StartDatePicker.Color := clWhite;
    StartDatePicker.Date := 45579.658078703700000000;
    StartDatePicker.ElementPosition := epIgnore;
    StartDatePicker.Role := '';
    StartDatePicker.Text := '';
    StartDatePicker.Visible := False;
    SetEvent(StartDatePicker, Self, 'OnChange', 'DatePickerChange');
    EndDatePicker.SetParentComponent(Self);
    EndDatePicker.Name := 'EndDatePicker';
    EndDatePicker.Left := 16;
    EndDatePicker.Top := 508;
    EndDatePicker.Width := 170;
    EndDatePicker.Height := 22;
    EndDatePicker.HeightStyle := ssAuto;
    EndDatePicker.WidthStyle := ssAuto;
    EndDatePicker.BorderStyle := bsSingle;
    EndDatePicker.ChildOrder := 3;
    EndDatePicker.Color := clWhite;
    EndDatePicker.Date := 45579.658086701390000000;
    EndDatePicker.ElementPosition := epIgnore;
    EndDatePicker.Role := '';
    EndDatePicker.Text := '';
    EndDatePicker.Visible := False;
    SetEvent(EndDatePicker, Self, 'OnChange', 'DatePickerChange');
    RefreshButton.SetParentComponent(Self);
    RefreshButton.Name := 'RefreshButton';
    RefreshButton.Left := 16;
    RefreshButton.Top := 536;
    RefreshButton.Width := 96;
    RefreshButton.Height := 25;
    RefreshButton.Caption := 'Refresh';
    RefreshButton.ChildOrder := 4;
    RefreshButton.ElementClassName := 'btn btn-secondary';
    RefreshButton.HeightPercent := 100.000000000000000000;
    RefreshButton.WidthPercent := 100.000000000000000000;
    SetEvent(RefreshButton, Self, 'OnClick', 'RefreshButtonClick');
    ConfigurationButton.SetParentComponent(Self);
    ConfigurationButton.Name := 'ConfigurationButton';
    ConfigurationButton.Left := 16;
    ConfigurationButton.Top := 624;
    ConfigurationButton.Width := 96;
    ConfigurationButton.Height := 25;
    ConfigurationButton.Caption := 'Configuration';
    ConfigurationButton.ChildOrder := 6;
    ConfigurationButton.HeightPercent := 100.000000000000000000;
    ConfigurationButton.WidthPercent := 100.000000000000000000;
    SetEvent(ConfigurationButton, Self, 'OnClick', 'ConfigurationButtonClick');
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 16;
    WebButton1.Top := 592;
    WebButton1.Width := 96;
    WebButton1.Height := 25;
    WebButton1.Caption := 'Refresh';
    WebButton1.ChildOrder := 7;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.Visible := False;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    UserDashboardButton.SetParentComponent(Self);
    UserDashboardButton.Name := 'UserDashboardButton';
    UserDashboardButton.Left := 120;
    UserDashboardButton.Top := 624;
    UserDashboardButton.Width := 96;
    UserDashboardButton.Height := 25;
    UserDashboardButton.Caption := 'User Dashboard';
    UserDashboardButton.ChildOrder := 7;
    UserDashboardButton.ElementClassName := 'btn btn-secondary';
    UserDashboardButton.HeightPercent := 100.000000000000000000;
    UserDashboardButton.WidthPercent := 100.000000000000000000;
    SetEvent(UserDashboardButton, Self, 'OnClick', 'UserDashboardButtonClick');
    ChartTimer.SetParentComponent(Self);
    ChartTimer.Name := 'ChartTimer';
    ChartTimer.Enabled := False;
    ChartTimer.Interval := 100;
    SetEvent(ChartTimer, Self, 'OnTimer', 'ChartTimerTimer');
    ChartTimer.Left := 832;
    ChartTimer.Top := 464;
    RefreshDashboardItemsTimer.SetParentComponent(Self);
    RefreshDashboardItemsTimer.Name := 'RefreshDashboardItemsTimer';
    RefreshDashboardItemsTimer.Enabled := False;
    SetEvent(RefreshDashboardItemsTimer, Self, 'OnTimer', 'RefreshDashboardItemsTimerTimer');
    RefreshDashboardItemsTimer.Left := 224;
    RefreshDashboardItemsTimer.Top := 480;
  finally
    WaitMessage.AfterLoadDFMValues;
    BranchTreeView.AfterLoadDFMValues;
    StartDatePicker.AfterLoadDFMValues;
    EndDatePicker.AfterLoadDFMValues;
    RefreshButton.AfterLoadDFMValues;
    ConfigurationButton.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    UserDashboardButton.AfterLoadDFMValues;
    ChartTimer.AfterLoadDFMValues;
    RefreshDashboardItemsTimer.AfterLoadDFMValues;
  end;
end;

end.



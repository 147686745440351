unit DashboardItemLikeForLike;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WebForm.Core,
  DB,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  Vcl.Controls,
  WEBLib.Grids,
  WEBLib.ExtCtrls,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  SharedDataModule,
  Simple.Dashboard.Return.Types,
  BaseDashboardItem,
  Data.DB, VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics,
  VCL.TMSFNCGraphicsTypes, VCL.TMSFNCChart, Vcl.Forms;


type
  TDashboardItemLikeForLike = class(TBaseDashboardItem)
  private
  protected
    procedure CreateComponents; override;
    procedure DisplayData; override;
    procedure NewSummarySelected; override;
    function GetChartHeight: integer; override;
    function GetSummaryRowCount: integer; override;
    function GetSummaryColCount: integer; override;
    procedure CreateSummaryFieldsTableRowIndex(ASummaryData: string; ARowIndex: integer); override;

  public
  end;


implementation

{ TDashboardItemLikeForLike }

const
  cSUFFIX = '_XX';

procedure TDashboardItemLikeForLike.CreateComponents;
begin
  inherited;
  CreateChart;
  CreateComboBox;
end;


procedure TDashboardItemLikeForLike.DisplayData;
begin
  inherited;
  AddChartAndData('');
  SummaryComboBox.ItemIndex := 0;
  NewSummarySelected;
end;

function TDashboardItemLikeForLike.GetChartHeight: integer;
begin
  result := 540;
end;

function TDashboardItemLikeForLike.GetSummaryColCount: integer;
begin
//  result := Length(Configuration.ColumnHeadings);
  result := SummaryFieldCount;
end;

function TDashboardItemLikeForLike.GetSummaryRowCount: integer;
begin
  result := 1;

end;

procedure TDashboardItemLikeForLike.NewSummarySelected;
var
  lDrillDownSQL: string;
  lDrillDownFieldName: string;
  lNewSummaryParam: string;

begin
  lNewSummaryParam := SummaryComboBox.Items[SummaryComboBox.ItemIndex];
//  lDrillDownFieldName := '@' + Configuration.DrillDownFieldName;
//  lDrillDownSQL := StringReplace(DrillDownSQL, lDrillDownFieldName, lNewSummaryParam, [rfReplaceAll, rfIgnoreCase]);
//  RunSummarySQL(lDrillDownSQL);
  CreateSummaryFieldsTableRowIndex(ChartData, SummaryComboBox.ItemIndex);
end;


procedure TDashboardItemLikeForLike.CreateSummaryFieldsTableRowIndex(ASummaryData: string; ARowIndex: integer);
var
  I, J: integer;
  //llJ: integer;
  lColumnName: string;
  lFieldType: string;
  lValue: JS.TJSObject;
  lSummaryData: string;
  lSummaryArray: JS.TJSArray;

  lCount: integer;
  lIterationSuffix: string;
  lMA: TSummaryArray;
begin
  lSummaryData := ASummaryData;
  lSummaryArray := JS.toArray(TJSJSON.parse(lSummaryData));

  try
    if not Assigned(SummaryGrid) then
    begin
      SummaryGrid := TTableControl.Create(Self);
      SummaryGrid.Parent := Self;
      SummaryGrid.ColCount := Length(Configuration.SummaryFields);
      SummaryGrid.RowCount := lSummaryArray.Length + 2;
      SummaryGrid.Height := (SummaryGrid.RowCount+2) * 28;
      SummaryGrid.Font.Height := 4;
      //KSSFSummaryGrid.Align := alTop;
      SummaryGrid.Align := alBottom;
      SummaryGrid.AlignWithMargins := True;
      SummaryGrid.Options.ImageAlign := taCenter;
    end;
    SummaryGrid.RowCount := 3;
    SummaryGrid.Height := 3 * 28;

    for J := 0 to 1 do
    begin
      lIterationSuffix := Format('_%d', [J]);
      for I := 0 to Length(Configuration.SummaryFields)-1 do
      begin
        //if I = 0 then
        //  FSummaryGrid.Cells[I,0] := FConfiguration.SummaryFields[I].DisplayName;

        lFieldType :=  Configuration.SummaryFields[I].FieldType;
        lColumnName := Configuration.SummaryFields[I].ToField;
        lColumnName := StringReplace(lColumnName, cSUFFIX, lIterationSuffix, []);

        lValue := JS.toObject(lSummaryArray[ARowIndex]);

        if Uppercase(lFieldType) = 'CURRDEC' then
          SummaryGrid.Cells[I,J+1] := '£' + String(lValue[lColumnName])
        else
          SummaryGrid.Cells[I,J+1] := String(lValue[lColumnName]);
      end;
    end;
  finally
  end;
end;


end.

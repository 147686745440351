unit DashboardItemTableOnly;

interface


uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  WebForm.Core,
  DB,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  Vcl.Controls,
  WEBLib.Grids,
  WEBLib.ExtCtrls,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  SharedDataModule,
  Simple.Dashboard.Return.Types,
  BaseDashboardItem,
  Data.DB, VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics,
  VCL.TMSFNCGraphicsTypes, VCL.TMSFNCChart, Vcl.Forms;


type
  TDashboardItemTableOnly = class(TBaseDashboardItem)
  private
  protected
    procedure CreateComponents; override;
    procedure DisplayData; override;
    procedure CreateSummaryTable(ARowCount: integer; AColumnCount: integer); override;//overload;
    function GetSummaryRowCount: integer; override;
    function GetSummaryColCount: integer; override;

  public
  end;


implementation

{ TDashboardItemDrillDown }

procedure TDashboardItemTableOnly.CreateComponents;
begin
  inherited;
end;


procedure TDashboardItemTableOnly.DisplayData;
begin
  CreateSummaryTable(GetSummaryRowCount, GetSummaryColCount);
  RunSummary(0);
end;

function TDashboardItemTableOnly.GetSummaryColCount: integer;
begin
//  result := Length(Configuration.ColumnHeadings);
  result := SummaryFieldCount;
end;

function TDashboardItemTableOnly.GetSummaryRowCount: integer;
begin
  result := 10;
end;

procedure TDashboardItemTableOnly.CreateSummaryTable(ARowCount,
  AColumnCount: integer);
var
  I, J: integer;
  lColumnName: string;
  lValue: JS.TJSObject;
  lSummaryData: string;
  lSummaryArray: JS.TJSArray;
  lFieldType: string;
begin
//  inherited;
  lSummaryData := SummaryData;
  lSummaryArray := JS.toArray(TJSJSON.parse(lSummaryData));

  try
    if not Assigned(SummaryGrid) then
    begin
      SummaryGrid := TTableControl.Create(Self);
      SummaryGrid.Parent := Self;
      SummaryGrid.ColCount := GetSummaryColCount;
      SummaryGrid.RowCount := GetSummaryRowCount+1;
      SummaryGrid.Height := (GetSummaryRowCount+1) * 28;
      SummaryGrid.Font.Height := 4;
      SummaryGrid.Align := alTop;
      SummaryGrid.AlignWithMargins := True;
      SummaryGrid.Options.ImageAlign := taCenter;
    end;

    for I := 0 to GetSummaryColCount-1 do
    begin
      //if I = 0 then
      SummaryGrid.Cells[I,0] := Configuration.SummaryFields[I].DisplayName;

      lFieldType :=  Configuration.SummaryFields[I].FieldType;
      lColumnName := Configuration.SummaryFields[I].ToField;
      lValue := JS.toObject(lSummaryArray[I]);

      for J := 0 to GetSummaryRowCount-1 do
      begin
        lValue := JS.toObject(lSummaryArray[J]);

        if Uppercase(lFieldType) = 'CURRDEC' then
          SummaryGrid.Cells[I,J+1] := '£' + String(lValue[lColumnName])
        else
          SummaryGrid.Cells[I,J+1] := String(lValue[lColumnName]);

      end;
    end;
  finally
  end;
end;

end.

